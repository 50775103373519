export default [
  {
    key: "no",
    label: "#",
    thStyle:{color:"white", textAlign: "center"}
  },
  {
    key: "serial",
    label: "Serial No.",
    thStyle:{color:"white", textAlign: "center"}
  },
  { key: "hwTypeName", label: "Hardware type", sortable: true , thStyle:{color:"white",width: "10% !important", textAlign: "center"}},
  { key: "hwBrandName", label: "Brand", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center",}},
  { key: "hwModelName", label: "Model", sortable: true ,thStyle:{color:"white",width: "10% !important", textAlign: "center",}},  
  { key: "description", label: "รายละเอียด", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "remark", label: "หมายเหตุ", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "status", label: "สถานะ", sortable: false , thStyle:{color:"white",width: "5% !important", textAlign: "center"}},
  {
    key: "type",
    label: "ประเภท",
    sortable: false,
    thStyle: {  textAlign: "center" ,color:"white",width: "5% !important"},
  },
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "12% !important", textAlign: "center", color:"white" },
  },
];
