<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Stock Vserv</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <v-select
              style="width: 20%"
              id="brand"
              v-model="statusSelected"
              label="title"
              :options="statusOption"
              class="ml-1"              
              :clearable="false"
            />

              <b-button
                v-if="vservStockPermission=='create' || vservStockPermission=='edit'"
                variant="primary"
                class="ml-1 shadow"   
                v-b-modal.add-stock-vserve-modal  
                @click="vservStockData={}"          
                ><feather-icon icon="PlusCircleIcon" size="14"
              /> เพิ่ม </b-button>
            </b-form>
          </b-col>

          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
            >

            <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

            <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

            <template #cell(status)="row">
                <center>
                  <feather-icon
                    v-if="row.item.status == 'Normal'"
                    icon="CheckCircleIcon"
                    class="cursor-pointer text-primary"
                  />
                  <feather-icon
                    v-else
                    icon="XIcon"
                    class="cursor-pointer text-danger"
                  />
                </center>
              </template>

              <template #cell(action)="row" v-if="vservStockPermission=='edit'">
                <center>
                  <b-button                     
                    variant="outline-secondary" class="mr-1" size="sm" pill @click="onSelectItemForEdit(row.item)"
                    v-b-modal.add-stock-vserve-modal        >
                    <feather-icon                    
                    icon="EditIcon"
                    class="cursor-pointer text-secondary"   
                    
                  />
                </b-button>
                  <b-button                     
                    variant="danger" size="sm" pill @click="deleteVservStock(row.item.id)">
                    <feather-icon                    
                    icon="TrashIcon"
                    class="cursor-pointer text-white"
                  />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <AddStockModal @saveAndCloseModal="saveAndCloseModal" :vservStockData="vservStockData"/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner
} from "bootstrap-vue";
import vSelect from "vue-select";
import AddStockModal from "./component/modal/addStock.vue"

import Field from "./field";
import VservStockService from "@/services/stock/vserv"

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner,
    vSelect,
    AddStockModal
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],
      statusSelected: { title: "ปกติ", value: "Normal" },
      statusOption: [
        { title: "ปกติ", value: "Normal" },
        { title: "เสีย", value: "Unnormal" },
        { title: "ขายซาก", value: "Sale" },
      ],
      isLoading:false,
      vservStockData:{}
    };
  },
  computed: {
    vservStockPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("STK001")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission         
    },
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("STK001") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    }
  },

  watch:{
    statusSelected(data){
      this.getVservStock(data.value)
    }
  },

  mounted() {
    this.getVservStock()
    
  },
  methods: {
    async getVservStock(status="normal"){
      this.isLoading = true
      const result = await VservStockService.GetVservStock(status)
      this.items = result.data.data
      this.totalRows = this.items.length;
      this.isLoading = false
    },

    async deleteVservStock(id){
      const isConfirm = confirm("ต้องการลบรายการนี้หรือไม่ ?")
      if(!isConfirm){
        return false
      }

      const result = await VservStockService.DeleteVservStock(id)
      await this.getVservStock()
    },

    async saveAndCloseModal(){       
      await this.getVservStock()
    },

    onSelectItemForEdit(item){
      this.vservStockData = item    
    },
    
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
