<template>
  <div>
    <b-modal
      ref="add-stock-vserve-modal"
      id="add-stock-vserve-modal"
      title="Stock Vserv"
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <b-card-text>
        <!--- START (Serial) --->  
        <b-row>
          <b-col md="6" class="">
            <label>Serial No. <span class="text-danger">*</span></label>
            <b-form-input
              id="smallInput"
              size="sm"
              placeholder="1804HJI093DJJ09921"
              v-model="vservStockData.serial"
            />
          </b-col>
          <b-col md="6" class=""></b-col>
        </b-row>
        <!--- END (Serial) --->

        <!--- START (ประเภทอุปกรณ์) --->
        <b-row class="mt-2">
          <b-col><b class="text-primary">ข้อมูลอุปกรณ์</b> </b-col>
        </b-row>

        <b-row>
          <b-col md="4" class="">
            <label>Hardware type</label>
            <v-select
              id="brand"
              v-model="hwTypeSelected"
              label="hwTypeName"
              :options="hwTypeOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeHwType"
            />
          </b-col>
          <b-col md="4" class="">
            <label>Brand</label>
            <v-select
              id="brand"
              v-model="hwBrandSelected"
              label="hwBrandName"
              :options="hwBrandOption"
              class="select-size-sm"
              :clearable="false"
              @input="onChangeHwBrand"
            />
          </b-col>
          <b-col md="4" class="">
            <label>Model</label>
            <v-select
              id="brand"
              v-model="hwModelSelected"
              label="hwModelName"
              :options="hwModelOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
        </b-row>
        <!--- END (ประเภทอุปกรณ์) --->

        <!--- START (เลขที่ใบงาน) --->
        <b-row class="mt-2">
          <b-col><b class="text-primary">ประเภทและสถานะ</b> </b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="">
            <label>ประเภทเครื่อง</label>
            <v-select
              id="brand"
              v-model="typeSelected"
              label="title"
              :options="typeOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>
          <b-col md="4" class="">
            <label>สถานะ</label>
            <v-select
              id="brand"
              v-model="statusSelected"
              label="title"
              :options="statusOption"
              class="select-size-sm"
              :clearable="false"
            />
          </b-col>

          <b-col md="4" class="">
            <label>ส่งให้ช่าง</label>
            <v-select
              id="brand"
              v-model="employeeSelected"
              label="fullname"
              :options="employeeOption"
              class="select-size-sm"
              :clearable="false"
              :disabled="mode=='create'"
            />
          </b-col>
        </b-row>
        <!--- END (เลขที่ใบงาน) --->

        <!--- START (รายละเอียด) --->
        <b-row class="mt-1">
          <b-col md="12" class="">
            <label>รายละเอียด</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="รายละเอียด"
              rows="2"
              size="sm"
              v-model="vservStockData.description"
            />
          </b-col>
        </b-row>
        <!--- END (รายละเอียด) --->

        <!--- START (หมายเหตุ) --->
        <b-row class="mt-1">
          <b-col md="12" class="">
            <label>หมายเหตุ</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="หมายเหตุ"
              v-model="vservStockData.remark"
              rows="2"
              size="sm"
            />
          </b-col>
        </b-row>
        <!--- END (หมายเหตุ) --->

        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-1">
          <b-col md="12" class="d-flex justify-content-end">           
            <b-button 
            v-if="vservStockPermission=='create' || vservStockPermission=='edit'"
            variant="primary" @click="save" 
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICES
import UserService from "@/services/user";
import HardwareService from "@/services/setting/hardware";
import VservStockService from "@/services/stock/vserv";

export default {
  props:['vservStockData'],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BForm,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      employeeSelected: {userId:0, fullname:"-"},
      hwTypeSelected: {},
      hwBrandSelected: {},
      hwModelSelected: {},
      statusSelected: { title: "ปกติ", value: "Normal" },
      typeSelected: { title: "Rotation", value: "Rotation" },
      employeeOption: [{userId:0, fullname:"-"}],
      hwTypeOption: [],
      hwBrandOption: [],
      hwModelOption: [],
      statusOption: [
        { title: "ปกติ", value: "Normal" },
        { title: "เสีย", value: "Unnormal" },
        { title: "ขายซาก", value: "Sale" },
      ],
      typeOption: [
        { title: "Rotation", value: "Rotation" },
        { title: "Setup&Replace", value: "SetupAndReplace" },
      ],
      isLoading: false,      
      mode:"create",
      userLogin:{},
      serialInit:""
    };
  },
  created(){
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;
    this.getHwType();
    this.getUsers();
  },
  computed :{
    vservStockPermission () {
      return this.$store.getters["user/getPermissionPage"]("STK001")
    }
  },
  watch:{
    vservStockData(data){
      if(data.id){
        this.mode = "edit"
        this.serialInit = data.serial
        this.getHwType()
        this.restoreData()
      }else{
        this.mode = "create"
      }
    },
  },

  methods: {
    validateForm(){
      if(!this.vservStockData.serial){
        alert("กรุณากรอกเลข Serial")
        return true
      }

      return false
    },
    async save() {
      const invalid = this.validateForm()
      if(invalid) return;

      if (this.vservStockData.id) {
        if(this.serialInit !==this.vservStockData.serial){
          const isDup = await this.checkDupSerial(this.vservStockData.serial, `modelId=${this.hwModelSelected.id}`)
          if(isDup){
            return
          }
        }

        await this.editVservStock()
        this.hideModal();
      } else {
        const isDup = await this.checkDupSerial(this.vservStockData.serial, `modelId=${this.hwModelSelected.id}`)
        if(isDup){
          return
        }

        await this.insertVservStock();
        this.hideModal();
      }
    },

    async checkDupSerial(serial, queryString){
      const result = await VservStockService.CheckDupSerial(serial, queryString)
      if(result.data.isDuplicate){
        this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Vserv Stock`,
                icon: "XIcon",
                variant: "danger",
                text: `ไม่สามารถใช้ Serial ซ้ำได้`,
              },
            });
            return true
      }

      return false
    },
    async insertVservStock() {      
      
      const obj = {
        ...this.vservStockData,
        hwTypeId: this.hwTypeSelected.id,
        hwBrandId: this.hwBrandSelected.id,
        modelId: this.hwModelSelected.id,
        status: this.statusSelected.value,
        assignTo: this.employeeSelected.userId,
        type: this.typeSelected.value,
        createBy:this.userLogin.userId
      };
      const result = await VservStockService.InsertVservStock(obj);
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Vserv Stock`,
              icon: "CheckIcon",
              variant: "success",
              text: `เพิ่มรายการเรียบร้อย`,
            },
          });        
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Vserv Stock`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการเพิ่มรายการ`,
            },
          });
        }
    },
    async editVservStock() {
      const obj = {
        ...this.vservStockData,
        hwTypeId: this.hwTypeSelected.id,
        hwBrandId: this.hwBrandSelected.id,
        modelId: this.hwModelSelected.id,
        status: this.statusSelected.value,
        assignTo: this.employeeSelected.userId,
        type: this.typeSelected.value,
        createBy:this.userLogin.userId
      };
      const result = await VservStockService.UpdateVservStock(obj, obj.serial);
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Vserv Stock`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขรายการเรียบร้อย`,
            },
          });
         
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Vserv Stock`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการแก้ไขรายการ`,
            },
          });
        }
    },
    async getUsers() {
      const result = await UserService.GetUserList({});
      this.employeeOption = [...this.employeeOption, ...result.data.data];
      // if (result.data.data.length > 0) {
      //   this.employeeSelected = result.data.data[0];
      // }
    },
    async getHwType() {
      const result = await HardwareService.GetHwTypeList("stock");
      this.hwTypeOption = result.data;

      if (result.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data[0];
        } else {
          item = this.hwTypeOption.find(
            (f) => f.id == this.vservStockData.hwTypeId
          );
          if (!item) {
            item = result.data[0];
          }

          console.log("item type", item);
        }
        this.hwTypeSelected = item;
        await this.onChangeHwType(item);
      } else {
        this.hwTypeSelected = {};
      }
    },

    async getHwBrand(typeId) {
      console.log(typeId);
      const result = await HardwareService.GetHwBrandList(typeId, "stock");
      this.hwBrandOption = result.data;

      if (result.data.length > 0) {
        let item = {};
        if (this.mode == "create") {
          item = result.data[0];
        } else {
          item = this.hwBrandOption.find(
            (f) => f.id == this.vservStockData.hwBrandId
          );

          if (!item) {
            item = result.data[0];
          }
        }

        this.hwBrandSelected = item;
        await this.onChangeHwBrand(item);
      } else {
        this.hwBrandSelected = {};
      }
    },

    async getHwModel(brandId) {
      const result = await HardwareService.GetHwModelList(brandId, "stock");
      this.hwModelOption = result.data;

      if (result.data.length > 0) {
        if (this.mode == "create") {
          this.hwModelSelected = result.data[0];
        } else {
          let findHwModel = this.hwModelOption.find(
            (f) => f.id == this.vservStockData.modelId
          );


          if (!findHwModel) {
            findHwModel = result.data[0];
          }
          this.hwModelSelected = findHwModel;
        }
      } else {
        this.hwModelSelected = {};
      }
    },

    restoreData(){
      const findEmp = this.employeeOption.find(f=>f.userId == this.vservStockData.assignTo)
      this.employeeSelected = findEmp

      const findStatus = this.statusOption.find(f=>f.value == this.vservStockData.status)
      this.statusSelected = findStatus

      const findType = this.typeOption.find(f=>f.value == this.vservStockData.type)
      this.typeSelected = findType    
    },

    onChangeHwType(item) {
      this.getHwBrand(item.id);
    },

    onChangeHwBrand(item) {
      this.getHwModel(item.id);
    },
    hideModal() {
      this.$refs["add-stock-vserve-modal"].hide();
      this.$emit("saveAndCloseModal")
      
    },
  },
  mounted() {

  },
};
</script>

<style>
@import "./style.css";
</style>
