import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const VservStockService = () => {}

VservStockService.GetVservStock = async(status)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/vserv/status/${status}`, {})
}

VservStockService.GetVservStockById = async(id)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/vserv/${id}`, {})
}

VservStockService.CheckDupSerial = async(serial, queryString="")=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/vserv/checkSerial/${serial}?${queryString}`, {})
}

VservStockService.InsertVservStock = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/stock/vserv`, payload)
}

VservStockService.UpdateVservStock = async(payload, serial)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/stock/vserv/${serial}`, payload)
}

VservStockService.DeleteVservStock = async(id)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/stock/vserv/${id}`, {})
}



 export default VservStockService